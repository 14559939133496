import { Component, OnInit } from '@angular/core';
import { PageService } from '../core/services/page.service';

@Component({
  selector: 'app-shop-from-home',
  templateUrl: './shop-from-home.component.html',
  styleUrls: ['./shop-from-home.component.scss']
})
export class ShopFromHomeComponent implements OnInit {

  constructor(private pageService: PageService) { }
  fbURL = 'https://www.facebook.com/';
  igURL = 'https://www.instagram.com/';

  shopList: any = [];

  ngOnInit() {
    this.pageService.getshoplist(localStorage.getItem('lang'))
      .subscribe(response => {
        
        this.shopList = response.data;
      });
  }

}
