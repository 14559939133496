import { Component, Renderer2, HostListener } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "Hyatt Plaza";
  isShow: boolean;
  topPosToStartShowing = 10;

  constructor(
    private renderer: Renderer2,
    private translateService: TranslateService
  ) {
    let selectedLanguage = localStorage.getItem("lang");
    if (selectedLanguage === "") {
      selectedLanguage = "en";
    }
    this.renderer.addClass(document.body, "switch_" + selectedLanguage);
    translateService.setDefaultLang(selectedLanguage);
    translateService.use(selectedLanguage);
  }

  @HostListener("window:scroll")
  checkScroll() {
    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop
    // returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    // console.log("[scroll]", scrollPosition);
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
}
