import { Component, OnInit } from '@angular/core';
import { CommonService, GenericPageService } from '../../core';
import { PageService } from 'src/app/core/services/page.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  pageBanner: any = [];
  pageBannerLoaded: boolean = false;
  pageTitle;
  constructor(
    private pageService: PageService,
    private genericService: GenericPageService
  ) { }

  ngOnInit() {

    this.pageService.getPageBanner('terms', localStorage.getItem('lang'))
    .subscribe(response => {
      this.pageBanner = response.data;
      //this.pageTitle = response.data.title;
      this.pageBannerLoaded = true;
    });
  }
   

}
