import { PageService } from './../core/services/page.service';
import { Component, OnInit } from '@angular/core';
import { NgForm }   from '@angular/forms';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit {
  careersDataLoaded = false;
  hyattCareer ;
  banner: any = '';
  pageTitle: any = '';
  showTab = 1;
  careerIndex ;
  apiStatus ;
  postClass ='';
  constructor(private pageService: PageService) { }

  ngOnInit() {
    this.pageService.getCareers(localStorage.getItem('lang'))
    .subscribe(careersList => {
      this.apiStatus = careersList.status;
      this.hyattCareer = careersList.data;
      this.careersDataLoaded = true;
      if(this.apiStatus==204){
        this.postClass = "no-items";
      }
    });

    this.pageService.getPageBanner('careers', localStorage.getItem('lang'))
    .subscribe(response => {
      this.banner = response.data.cover;
      this.pageTitle = response.data.title;
    });
  }

  tabToggle(event, index) {
    // this.showTab = index;
    this.careerIndex = index;
    //console.log(this.careerIndex);
  }
  form_submit(form: NgForm){
    if (localStorage.getItem('lang') === 'ar') {
      document.getElementById('message').innerHTML = "شكرا! تم إرسال الت�?اصيل الخاصة بك بنجاح. سو�? نعود اليك قريبا";
    }else{
      document.getElementById('message').innerHTML = "Thank You! Your details submitted successfully. We will get back to you soon";
    }
    // this.setTimeout(() => {
    //   document.getElementById('message').style.display = 'none';

    // }, 3000);
  }



}
