//import { Component, OnInit } from '@angular/core';
//import { PageService } from './../core/services/page.service';


import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/core';
import { PageService } from 'src/app/core/services/page.service';
import { HttpClient } from '@angular/common/http';
//import { Subscriber } from 'rxjs';




@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  banner = '';
  pageTitle = '';


  contactusForm: FormGroup;
  submitted = false;
  responseText: string;



  constructor(
    private commonService: CommonService,
    private pageService: PageService,
    private http: HttpClient
  ) {

    this.contactusForm = new FormGroup(
      {
        email: new FormControl("", [Validators.required, Validators.email]),
          name: new FormControl(''),
          mobile: new FormControl(''),
          country: new FormControl(''),
          message: new FormControl('')
      });





   }

  ngOnInit() {
    this.pageService.getPageBanner('contactus', localStorage.getItem('lang'))
    .subscribe(response => {
      this.banner = response.data.cover;
      this.pageTitle = response.data.title;
    });
  }



  contactusFormSubmit()
  {
    this.submitted = true;


    //const name =  this.contactusForm.get('name').value;
    //console.log("Form Value : " + name); 
    console.log("enter onsubmit");
    

    if(this.contactusForm.get('email').value == ''){
      alert("enter valid email");
    }
 
    if (this.contactusForm.valid)  {
      const contactusFormData = this.contactusForm.value;
     // console.log("email id" + this.contactusForm.get('email').value);
      
      const email = this.contactusForm.get('email').value;
      const name = this.contactusForm.get('name').value;
       const mobile = this.contactusForm.get('mobile').value;
      
      const country = this.contactusForm.get('country').value;
      const message = this.contactusForm.get('message').value;
      const data = {
        "email":email,
        "name":name,
        "mobile":mobile,
        "country":country,
        "message":message
      } 
     // console.log("ALl Value : " + name+ mobile+ country +message);
      // newsFormData.append('email', this.newsletterForm.get('email').value);
      // const formData = { email : this.newsletterForm.get('email').value };
      this.pageService.postcontactusForm(data).subscribe(

        response => {
          console.log(response);
          if (response.status === 200) {
            this.revert();
            this.responseText = response.message  ? response.message : 'Thanks for your submission!';
           
          }
        }
      );
    }

  }


  get f()
  {
    return this.contactusForm.controls;
  }

  revert() {
    this.contactusForm.reset();
  }

}
