import { Component, OnInit } from '@angular/core';
import { CommonService, GenericPageService } from '../../core';
import { PageService } from 'src/app/core/services/page.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  pageBanner: any = [];
  pageBannerLoaded: boolean = false;

  constructor(
    private commonService: PageService,
    private genericService: GenericPageService
  ) { }

  ngOnInit() {

    this.commonService.getPageBanner('privacy', localStorage.getItem('lang'))
    .subscribe(banner => {
      this.pageBanner = banner.data;
      this.pageBannerLoaded = true;
    });

  }

}
