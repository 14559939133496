import { GenericPageService } from './../core/services/generic-page.service';
import { CommonService } from 'src/app/core';
import { ActivatedRoute } from '@angular/router';
import { PageService } from './../core/services/page.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-store-listing',
  templateUrl: './store-listing.component.html',
  styleUrls: ['./store-listing.component.scss']
})
export class StoreListingComponent implements OnInit {

  nrSelect = '';
  nrText = '';
  shops: any ;
  subCategoryID :any;
  category = 1;
  type: any;
  pageBanner: any = [];
  pageBannerLoaded = false;
  selectedCategory = '';
  dropDownCategories: any = [];

  constructor(
    private pageService: PageService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private genericService: GenericPageService
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(routeParams => {
      this.subCategoryID = routeParams.id;
      this.type = routeParams.type;
      this.nrText = (routeParams.key)?routeParams.key:'';
      this.nrSelect = this.subCategoryID;
      if(this.nrText !==''){
        this.searchNow();
      }else{
        this.shopList();
      }
    });

    this.commonService.getPageBanner(this.type)
    .subscribe(banner => {
      this.pageBanner = banner.data;
      this.pageBannerLoaded = true;
    });
    this.loadCategories();
  }
  shopList() {
    this.category = (this.type === 'shopping') ? 1 : 2;
    this.pageService.getShopList(localStorage.getItem('lang'), this.category, this.subCategoryID)
        .subscribe( shop => {
            this.shops = shop.data;
        });
  }

  loadCategories()
  {
    this.category = (this.type === 'shopping') ? 1 : 2;
    this.genericService.getInnerPageCategories(this.category, localStorage.getItem('lang'))
      .subscribe( category =>{
          this.dropDownCategories = category;
    });
  }

  searchNow() {
    this.category = (this.type === 'shopping') ? 1 : 2;
    this.pageService.getSearchShopList(localStorage.getItem('lang'), this.category, this.nrSelect, this.nrText)
        .subscribe( shop => {
            this.shops = shop.data;
        });
    // console.log(this.nrSelect);
    // console.log(this.nrText);
  }

}
