import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shop-details',
  templateUrl: './shop-details.component.html',
  styleUrls: ['./shop-details.component.scss']
})
export class ShopDetailsComponent implements OnInit {
 
  offers = [
    {
      image: 'assets/hyattplaza-images/shopping/offers.png'
    },
    {
      image: 'assets/hyattplaza-images/shopping/offers.png'
    },
    {
      image: 'assets/hyattplaza-images/shopping/offers.png'
    },
    {
      image: 'assets/hyattplaza-images/shopping/offers.png'
    },
    {
      image: 'assets/hyattplaza-images/shopping/offers.png'
    },
    {
      image: 'assets/hyattplaza-images/shopping/offers.png'
    },
    {
      image: 'assets/hyattplaza-images/shopping/offers.png'
    },
    {
      image: 'assets/hyattplaza-images/shopping/offers.png'
    }
  ]
  rel_shop =[
    {
      image: 'assets/hyattplaza-images/shopping/relsh.png'
    },
    {
      image: 'assets/hyattplaza-images/shopping/relsh.png'
    },
    {
      image: 'assets/hyattplaza-images/shopping/relsh.png'
    },
    {
      image: 'assets/hyattplaza-images/shopping/relsh.png'
    },
    {
      image: 'assets/hyattplaza-images/shopping/relsh.png'
    },
    {
      image: 'assets/hyattplaza-images/shopping/relsh.png'
    },
    {
      image: 'assets/hyattplaza-images/shopping/relsh.png'
    },
    {
      image: 'assets/hyattplaza-images/shopping/relsh.png'
    },
  ];

 

  slideConfig3 = {
    dots: false,
    arrows: true,
    speed:300,
    slidesToShow:3,
    slidesToScroll: 4,
  }
  slideConfig4 = {
    dots: false,
    arrows: true,
    speed:300,
    slidesToShow:2,
    slidesToScroll: 4,
  }


  constructor() { }

  ngOnInit() {
  }

}
