import { Component, OnInit } from '@angular/core';
import { PageService } from '../core/services/page.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-search-listing',
  templateUrl: './search-listing.component.html',
  styleUrls: ['./search-listing.component.scss']
})
export class SearchListingComponent implements OnInit {

  searchKeyword;
  resultData: any = [];
  data: any = [];
  stores: any = [];
  events: any = [];
  offers: any = [];
  constructor(
    private pageService: PageService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe(routeParams => {
      this.searchKeyword = routeParams.keyword;
      console.log('mykey' + this.searchKeyword);
      this.getSearchResult();
  });
  }

  ngOnInit() {
  }

  getSearchResult() {
    console.log(this.searchKeyword);
    this.pageService.getSearchKeyword(this.searchKeyword).subscribe(

      response => {
        console.log(response);
        if (response.status === 200) {
          // this.responseText = response.message  ? response.message : 'Thanks for your subscription!';
          console.log(response);
          this.resultData = response.data;
        }
      }
    );
  }

}
