import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService, HyattEventService } from 'src/app/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header" style="z-index:99999 !important;">
    <h4 class="modal-title">Register for {{eventName}}</h4>
    <button type="button" class="close" aria-label="Close"
    (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <form [formGroup]="eventForm" (ngSubmit)="registerEvent(eventId)">
    <div class="card">
    <div class="modal-body">     
        <div class="form-group">
          <label for="name">Name</label>
          <input type="text" 
            class="form-control"
            formControlName="name" />
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input type="text" formControlName="email" class="form-control">
        </div>

       <div class="form-group">
        <label for="mobileNumber">Mobile Number</label>
        <input type="text" formControlName="mobileNumber" class="form-control">
       </div>

      </div>
    </div>
    <div class="modal-footer">
      <button class="vip-button"
        [disabled]="!eventForm.valid">
        Register Now
      </button>
    </div>
  </form>
  `
})
export class NgbdEventModalContent {
  @Input() eventId;
  @Input() eventName;

  eventForm: FormGroup;
  constructor(
     public activeModal: NgbActiveModal,
     private router: Router,
     private formBuilder: FormBuilder,
     private eventService: HyattEventService
     ){
       this.createEventForm();
     }
     
  registerEvent(eventId) {
    if (this.eventForm.valid)
    {
        const formValues = this.eventForm.value;
        const formData = [];
        formData['eventId'] = eventId;
        formData['name'] = formValues.name;
        formData['email'] = formValues.email;
        formData['mobileNumber'] = formValues.mobileNumber;

        this.eventService.registerEvent(formData).subscribe(
          response => {
            if (response.status === 200) {
             console.log(response.message);
            }
          }
        );

    }
  }

  createEventForm()
  {
    this.eventForm = this.formBuilder.group({
      name: '',
      email: new FormControl("", [Validators.required, Validators.email]),
      mobileNumber: ''
    });
  }

}


@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})

export class EventDetailComponent implements OnInit {


  eventId: number;
  eventName = '';
  events = [
    {
      event_date:'01/04/2019- 05/05/2019',
      event_time:'10:00 AM - 05:00 PM',
      event_location:'Lorem Ipsum',
      events_heading:'EVENT DETAIL PAGE',
      events_details:"Et eirmod ea sea lorem sea aliquyam sed sea sea, invidunt magna sed et dolores et, amet sed stet takimata sadipscing stet. Sanctus magna invidunt dolor dolor kasd sit lorem. Ipsum amet diam ipsum eirmod sed dolor sea justo accusam, sit erat sit labore."
      
    }
  ]
  events_images = [
    {
      event_img:'assets/events/797.png',
      event_map:'assets/events/MAP.png',
    }
  ]

  categories = [
    {
       
      image: 'assets/hyattplaza-images/events/1.png'
    },
    {
      
      image: 'assets/hyattplaza-images/events/2.png'
    },
    {
      
      image: 'assets/hyattplaza-images/events/3.png'
    },
    {
      
      image: 'assets/hyattplaza-images/events/4.png'
    },
    {
       
      image: 'assets/hyattplaza-images/events/1.png'
    },
    {
       
      image: 'assets/hyattplaza-images/events/4.png'
    },
    {
       
      image: 'assets/hyattplaza-images/events/3.png'
    },
    {
       
      image: 'assets/hyattplaza-images/events/2.png'
    }
  ];

  pageBanner: Array<string> = [];
  pageBannerLoaded: boolean = true;
  eventDetail: Array<string> = [];
 

  slideConfig = {
    dots: false,
    arrows: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 4,
  };

  
  slideConfigmob = {
    dots: false,
    arrows: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private eventService: HyattEventService,
    private modalService: NgbModal
    ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(routeParams => {
      this.eventId = routeParams.id;
      this.loadEventDetail();
  });

  this.commonService.getPageBanner('events')
      .subscribe(banner => {
      this.pageBanner = banner.data;
      this.pageBannerLoaded = true;
  });
  }

  loadEventDetail()
  {

    this.eventService.getEventDetail( this.eventId, localStorage.getItem('lang'))
        .subscribe( event => {
            this.eventDetail = event.data;
        });
  }

  open(eventId, eventName) {
    // console.log(eventId);
    const modalRef = this.modalService.open(NgbdEventModalContent);
    modalRef.componentInstance.eventId  = eventId;
    modalRef.componentInstance.eventName  = eventName;
  }

}
