import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SharedModule } from './common/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SlickModule } from 'ngx-slick';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { LightboxModule } from 'ngx-lightbox';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { InnerModule } from './modules/inner.module';
import { HomeComponent } from './home/home.component';
import { CategoriesComponent } from './categories/categories.component';
import { ShopDetailsComponent } from './shop-details/shop-details.component';
import { VipcardComponent } from './vipcard/vipcard.component';
import { OffersComponent, NgbdModalContent } from './offers/offers.component';
import { HadiyaComponent } from './hadiya/hadiya.component';
import { LeasingComponent } from './leasing/leasing.component';
import { ContactComponent } from './contact/contact.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AboutComponent } from './about/about.component';
import { CareerComponent } from './career/career.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreListingComponent } from './store-listing/store-listing.component';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { SearchListingComponent } from './search-listing/search-listing.component';
import { ShopFromHomeComponent } from './shop-from-home/shop-from-home.component';
import { ShopTimingsComponent } from './shop-timings/shop-timings.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CategoriesComponent,
    ShopDetailsComponent,
    VipcardComponent,
    OffersComponent,
    HadiyaComponent,
    LeasingComponent,
    ContactComponent,
    AboutComponent,
    CareerComponent,
    NgbdModalContent,
    StoreListingComponent,
    SearchListingComponent,
    ShopFromHomeComponent,
    ShopTimingsComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    CoreModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
       }
      }
    ),
    SharedModule,
    InnerModule,
    AppRoutingModule,
    SlickModule.forRoot(),
    AngularFontAwesomeModule,
    RouterModule,
    CommonModule,
    LightboxModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    TranslateModule
  ],
  providers: [TranslateService],
  bootstrap: [AppComponent],
  entryComponents: [ NgbdModalContent]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}