import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable()
export class PageService {
  constructor(
    private apiService: ApiService
  ) { }

  getVipcards(lang: string = 'en'): Observable<any> {
    return this.apiService.get('/Category/loadVipParticipants?language=' + lang)
      .pipe(map(data => data));
  }

  getOffers(lang: string = 'en'): Observable<any> {
    return this.apiService.get('/Offers/loadOffers?language=' + lang)
      .pipe(map(data => data));
  }

  getPageBanner(page: string = 'aboutus', lang: string = 'en'): Observable<any> {
    return this.apiService.get('/PageBanner/loadPageBanner?language=' + lang + '&pageKey=' + page)
      .pipe(map(data => data));
  }

  getGuestServices(lang: string = 'en'): Observable<any> {
    return this.apiService.get('/Brands/loadGustService?language=' + lang)
      .pipe(map(data => data));
  }

  getHadiyaStores(lang: string = 'en'): Observable<any> {
    return this.apiService.get('/Category/loadHadiyaStores?language=' + lang)
      .pipe(map(data => data));
  }

  postLeasingForm(formData) {
    return this.apiService.formDataPost(
      '/Category/LeasingMail', formData
    );
  }

  getNewsDetail(newsID: number = 0, lang: string = 'en'): Observable<any> {
    return this.apiService.get('/News/loadNewsDetails?language=' + lang + '&newsID=' + newsID)
      .pipe(map(data => data));
  }

  getRelatedNewsDetail(newsID: number = 0, lang: string = 'en'): Observable<any> {
    return this.apiService.get('/News/loadRelatedNews?language=' + lang + '&newsID=' + newsID)
      .pipe(map(data => data));
  }

  getCMSContent(field: string = '', lang: string = 'en'): Observable<any> {
    return this.apiService.get('/Brands/cmsContentShow?language=' + lang + '&field=' + field)
      .pipe(map(data => data));
  }

  getHistory(lang: string = 'en'): Observable<any> {
    return this.apiService.get('/Category/history?language=' + lang)
      .pipe(map(data => data));
  }

  getMarketingData(lang: string = 'en'): Observable<any> {
    return this.apiService.get('/Category/history?language=' + lang)
      .pipe(map(data => data));
  }

  getShopTiming(lang: string = 'en'): Observable<any> {
    return this.apiService.get('/ShopTimings/loadShopTimings?language=' + lang)
      .pipe(map(data => data));
  }

  getShopList(lang: string = 'en', categoryID = 1, subCategory = 1): Observable<any> {
    return this.apiService.get('/Category/loadShopList?language=' + lang + '&categoryID=' + categoryID + '&subcategoryID=' + subCategory)
      .pipe(map(data => data));
  }

  getSearchShopList(lang: string = 'en', categoryID = 1, subCategory, key = ''): Observable<any> {
    return this.apiService.get('/Category/loadShopList?language=' + lang + '&categoryID=' + categoryID + '&subcategoryID=' + subCategory + '&key=' + key)
      .pipe(map(data => data));
  }

  postHadiyaForm(formData) {
    return this.apiService.formDataPost(
      '/Category/LeasingMail', formData
    );
  }


  postVoucherForm(formData) {
    // console.log(formData);
    const sendData = {
      quentity1: formData.quentity1,
      quentity2: formData.quentity2,
      quentity3: formData.quentity3,
      deleveryOpt: formData.deleveryOpt,
      totalAmt: formData.totalAmt,
      name: formData.name,
      email: formData.email,
      mobileNumber: formData.mobileNumber,
      address: formData.address,
    };

    // console.log(sendData);
    return this.apiService.post('/Category/giftVoucherPurchase', sendData).pipe(map(data => data));
  }

  getCareers(lang: string = 'en'): Observable<any> {
    return this.apiService.get('/Careers/loadCareers?language=' + lang)
      .pipe(map(data => data));
  }

  postAboutData(aboutFormData: any) {
    // console.log("formdata" + aboutFormData);
    return this.apiService.post('/Category/enquire', aboutFormData).pipe(map(data => data));

  }

  postVipData(vipFormData: any) {
    // console.log("formdata" + vipFormData);
    return this.apiService.post('/Category/enquire', vipFormData).pipe(map(data => data));

  }


  postNewsletterForm(email: any) {
    // console.log("email" + email);
    return this.apiService.get('/NewsLetter/userSubscribe?email=' + email).pipe(map(data => data));
  }
  getSearchKeyword(keyword: any) {
    // console.log("Search" + keyword);


    return this.apiService.get('/Search/search?keyword=' + keyword)
      .pipe(map(data => data));

  }

  getshoplist(lang: string = 'en'): Observable<any> {
    // console.log(lang);
    return this.apiService.get('/ShopTimings/loadshops?language=' + lang)
      .pipe(map(data => data));
  }




  postcontactusForm(data: any) {
    // console.log("email" + data.email);
    // const email = data.email;
   // console.log("name" + value.name);
    return this.apiService.get('/NewsLetter/userContactus?email=' + data.email+'&name='+data.name+'&mobile='+data.mobile+'&country='+data.country+'&message='+data.message).pipe(map(data => data));
  }




}
