import { PageService } from './../core/services/page.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  aboutForm: FormGroup;
  submitted = false;
  responseText: string;

  constructor(
    private pageService: PageService
    ) {
      this.aboutForm = new FormGroup(
        {
            fullName: new FormControl("", [Validators.required]),
            email: new FormControl("", [Validators.required, Validators.email]),
            message: new FormControl("", [Validators.required]),
            phoneNumber: new FormControl("", [Validators.required])
        });
     }

  slideConfig = {
    dots: false,
    arrows: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: false
  };
  banner = '';
  pageTitle = '';
  content: any;
  history: any = [];
  marketing: any;
  leasingInfoData: any;
  historyTab = 0;

  switchVisible() {
    if (document.getElementById('Div1')) {
      
      this.responseText = '';
      this.submitted = false;

      if (document.getElementById('Div1').style.display == 'none') {
        document.getElementById('Div1').style.display = 'block';
        document.getElementById('Div2').style.display = 'none';
      } else {
        document.getElementById('Div1').style.display = 'none';
        document.getElementById('Div2').style.display = 'block';
      }
    }

    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.pageService.getPageBanner('aboutus', localStorage.getItem('lang'))
      .subscribe(response => {
        this.banner = response.data.cover;
        this.pageTitle = response.data.title;
      });

    this.pageService.getCMSContent('about', localStorage.getItem('lang'))
      .subscribe(response => {
        
        this.content = response.data[0];
      });

    this.pageService.getCMSContent('marketing_info', localStorage.getItem('lang'))
      .subscribe(response => {
        // console.log(response);
        this.marketing = response.data[0];
      });

    this.pageService.getCMSContent('leasing_info', localStorage.getItem('lang'))
      .subscribe(leasingresponse => {
        //console.log(leasingresponse);
        this.leasingInfoData = leasingresponse.data[0];
      });


    this.pageService.getHistory(localStorage.getItem('lang'))
      .subscribe(hresponse => {
        // console.log(response);
        this.history = hresponse.data;
      });
  }

  showHistoryTab(index) {
    this.historyTab = index;
  }

  get getAboutForm()
  {
    return this.aboutForm.controls;
  }

  onAboutFormSubmit()
  {
    this.submitted = true;
    if(this.aboutForm.valid) 
    {
      const aboutFormData = this.aboutForm.value;
      const aboutForm = new FormData();

      this.pageService.postAboutData(aboutFormData).subscribe(

        response => {
          //console.log( response);
          if (response.status === 200) {
            this.responseText = response.message ? response.message : 'Enquiry sent successfully!';
            this.revert();
          }
        }
      );
    }
  }

  revert() {
    this.aboutForm.reset();
  }
}
