import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PageService } from './../core/services/page.service';
import { Component, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-hadiya',
  templateUrl: './hadiya.component.html',
  styleUrls: ['./hadiya.component.scss']
})
export class HadiyaComponent implements OnInit {

  banner = '';
  pageTitle = '';
  stores: Array<string> = [];
  hadiyaForm: FormGroup;
  submitted = false;
  responseStatus = false;
  responseText = '';
  totalPrice = 0;
  voucherOnePrice = 200;
  voucherTwoPrice = 100;
  voucherThreePrice = 50;
  showBuyOnlineBlock = false;
  
  constructor(
    private pageService: PageService,
    private formBuilder: FormBuilder
    ) { }

  ngOnInit() {

    this.hadiyaForm = this.formBuilder.group({
      cpn200: [''],
      cpn100: [''],
      cpn50: [''],
      delivery: [''],
      totalPrice: ['0'],
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      address: ['']
   });

   this.onValueChanges();

    this.pageService.getPageBanner('giftvoucher', localStorage.getItem('lang'))
      .subscribe(response => {
        //console.log(response);
        this.banner = response.data.cover;
        this.pageTitle = response.data.title;
      });

    this.pageService.getHadiyaStores(localStorage.getItem('lang'))
      .subscribe(responsehadiya => {
        // console.log(response);
        this.stores = responsehadiya.data;
      });     
  }

  onValueChanges()
  {
    this.hadiyaForm.get('cpn200').valueChanges.subscribe(val => {

      if(val !== null ) {         
         this.updatePrice(val, this.voucherOnePrice );
      }

    });

    this.hadiyaForm.get('cpn100').valueChanges.subscribe(val => {
      if(val !== null ) {      
       this.updatePrice(val, this.voucherTwoPrice );
      }      

    });

    this.hadiyaForm.get('cpn50').valueChanges.subscribe(val => {
      if(val !== null ) {     
       this.updatePrice(val, this.voucherThreePrice );
      }      

    });

    return;
  }

updatePrice(quantity, price)
{
    let currentPrice = 0;
    currentPrice = quantity * price; 
    let priceVal = this.f.totalPrice.value;
    if(priceVal !== null && priceVal !== 0)
    {     
      this.resetPrice();
    }
    else{
      priceVal = currentPrice;   
      this.f.totalPrice.patchValue(priceVal);
    }
}

resetPrice()
{
  let cp1Value = this.f.cpn200.value;
  let cp2Value = this.f.cpn100.value;
  let cp3Value = this.f.cpn50.value;
  let newPrice = 0;
  if(cp1Value !== null) {
    newPrice += cp1Value * this.voucherOnePrice;
  }
  if(cp2Value !== null) {
    newPrice += cp2Value * this.voucherTwoPrice;
  }
  if(cp3Value !== null) {
    newPrice += cp3Value * this.voucherThreePrice;
  }
  this.f.totalPrice.patchValue(newPrice);
}

toggleBuyOnlineBlock()
{
  this.showBuyOnlineBlock = (this.showBuyOnlineBlock) ? false : true;
  if(this.showBuyOnlineBlock) {
     // el.nativeElement.scrollIntoView(true);
    this.revert();
  }
}

onSubmit() {
  this.submitted = true;
  if (this.hadiyaForm.valid) 
  {
    const formData = this.hadiyaForm.value;

    const submittedData = [];
    submittedData['quentity1'] = formData.cpn200;
    submittedData['quentity2'] = formData.cpn100;
    submittedData['quentity3'] = formData.cpn50;
    submittedData['deleveryOpt'] = formData.delivery;
    submittedData['totalAmt'] = formData.totalPrice;
    submittedData['name'] = formData.name;
    submittedData['email'] = formData.email;
    submittedData['mobileNumber'] = formData.phone;
    submittedData['address'] = formData.address;

    this.pageService.postVoucherForm(submittedData).subscribe(
      response => {
        this.responseStatus = true;
        this.responseText = response.message;
        if (response.status === 200) {
          this.revert();
        }
      }
    );
  } else {   
    return;
  }

}
get f() { return this.hadiyaForm.controls; }

revert() {

  this.hadiyaForm.reset();
}

}
