import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PageService } from 'src/app/core/services/page.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  collapsed = 'close';
  topMenu: any [] = [];
  subMenu: any [] = [];
    topMenuLoaded: boolean = false;
  language: string = 'en';
  currentLanguages = [
    'en', 'ar'
  ];
  searchKeyword ;
  activeLanguage = '';
  searchForm: FormGroup;
  submitted = false;

  constructor(
    private commonService: CommonService,
    private router: Router,
    private pageService: PageService
    ) { }

  ngOnInit() {

    if (localStorage.getItem('lang') === null) {
      localStorage.setItem('lang', 'en');
    }

    this.commonService.getMainMenu(localStorage.getItem('lang'))
    .subscribe(menu => {
      this.topMenu = menu.data;
      this.topMenuLoaded = true;
    });


    this.activeLanguage = localStorage.getItem('lang');

  }

  changeLang( lang: string){
    localStorage.setItem('lang', lang);
    window.location = window.location;
  }

  menuToggle(val) {
    this.collapsed = val;

  }

  searchFormSubmit() {
    this.submitted = true;
    if (this.searchKeyword !== '') {
      console.log('keyword' + this.searchKeyword);
      this.router.navigate(['/search', this.searchKeyword]);
    }

  }
}
