import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/core';
import { PageService } from 'src/app/core/services/page.service';
import { HttpClient } from '@angular/common/http';
import { Subscriber } from 'rxjs';



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  respose: any;
  subscription(value) {
    console.log(value);
    console.log("RESPONSE" + this.respose);
    this.http.post('https://socialynerds.com/api/subscribe/index.php',JSON.stringify(value)).subscribe( response => this.respose = response);
   
   console.log(this.respose);

  }



  socialIcons: Array<string> = [];
  footerLinks: Array<string> = [];
  footerColOne: Array<string> = [];
  footerColTwo: Array<string> = [];
  footerContact: Array<string> = [];
  newsletterForm: FormGroup;
  submitted = false;
  responseText: string;


  constructor(
    private commonService: CommonService,
    private pageService: PageService,
    private http: HttpClient

    ) {

      this.newsletterForm = new FormGroup(
        {

            email: new FormControl("", [Validators.required, Validators.email])
  
        });

     }

  ngOnInit() {

    this.commonService.getFooterSocialIcons()
    .subscribe(icons => {
      this.socialIcons = icons;
    });

    this.commonService.getFooterLinks(localStorage.getItem('lang'))
      .subscribe(links => {
       this.footerLinks = links['data'].links;
       this.footerContact = links['data'].contact; 
       this.formatLinks();
    });

  }

  formatLinks()
  {
    this.footerColOne = this.footerLinks.filter( col => col['itemColumn'] == 1);
    this.footerColTwo = this.footerLinks.filter( col => col['itemColumn'] == 2);
  }


 
  onNewsletterFormSubmit()
  {
    this.submitted = true;
    console.log("enter onsubmit");

if(this.newsletterForm.get('email').value == ''){
  alert("enter valid email");
}



    if (this.newsletterForm.valid)  {
      const newsletterFormData = this.newsletterForm.value;
      console.log("email id" + this.newsletterForm.get('email').value);
      const email = this.newsletterForm.get('email').value;
      // newsFormData.append('email', this.newsletterForm.get('email').value);
      // const formData = { email : this.newsletterForm.get('email').value };
      this.pageService.postNewsletterForm(email).subscribe(

        response => {
          console.log(response);
          if (response.status === 200) {
            this.responseText = response.message  ? response.message : 'Thanks for your subscription!';
            this.revert();
          }
        }
      );
    }

  }





  get f()
  {
    return this.newsletterForm.controls;
  }

  revert() {
    this.newsletterForm.reset();
  }




}
