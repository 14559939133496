import { Component, OnInit, Injectable } from '@angular/core';
// import { CommonService, GenericPageService } from '../../../core';
import { CommonService, HyattEventService } from 'src/app/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-more-events',
  templateUrl: './more-events.component.html',
  styleUrls: ['./more-events.component.scss']
})
export class MoreEventsComponent implements OnInit {

  eventlist: Array<string> = [];
  pageBanner: any = [];
  pageBannerLoaded: boolean = false;
  
 
  blogs = [
    {
      img:'assets/hyattplaza-images/events/1.png',
      time: '3 Jan 1998',
      des: 'Enjoy SNOW WONDERLAND Num ere symeonnok walasth en kynzassal urodum fyodumtul. Num ere symeonnok walasth en kynzassal urodum fyodumtul.'
    },
    {
      img:'assets/hyattplaza-images/events/2.png',

      time: '3 May 1998',
      des: 'Enjoy SNOW WONDERLAND Num ere symeonnok walasth en kynzassal urodum fyodumtul.'
    },
    {
      img:'assets/hyattplaza-images/events/3.png',

      time: '22 Jan 2019',
      des: 'Enjoy SNOW WONDERLAND Num ere symeonnok walasth en kynzassal urodum fyodumtul.'
    }
    
  ]


  constructor(
    private commonService: CommonService,
    private hyatteventService: HyattEventService,
    private router: Router

  ) { }

  ngOnInit() {
    this.commonService.getPageBanner('events')
    .subscribe(banner => {
    this.pageBanner = banner.data;
    this.pageBannerLoaded = true;
});

this.hyatteventService.getEventList(localStorage.getItem('lang'))
.subscribe(response => {
  console.log(response);
  this.eventlist = response.data;
});



     }
   
    }