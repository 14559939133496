import { PageService } from './../core/services/page.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-vipcard',
  templateUrl: './vipcard.component.html',
  styleUrls: ['./vipcard.component.scss']
})
export class VipcardComponent implements OnInit {

  vipForm: FormGroup;
  submitted = false;
  responseText: string;
  vipcards: Array<string> = [];
  banner: any = '';
  pageTitle: any = '';

  constructor(private pageService: PageService) {
    this.vipForm = new FormGroup(
      {
          fullName: new FormControl('', [Validators.required]),
          email: new FormControl('', [Validators.required, Validators.email]),
          phoneNumber: new FormControl('', [Validators.required]),
          company: new FormControl('', [Validators.required])
      });
   }
  ngOnInit() {
    this.pageService.getVipcards(localStorage.getItem('lang'))
      .subscribe(response => {
        console.log(response);
        this.vipcards = response.data;
      });

    this.pageService.getPageBanner('vipcard', localStorage.getItem('lang'))
      .subscribe(response => {
        this.banner = response.data.cover;
        this.pageTitle = response.data.title;
      });
  }
  get getVipForm()  {
    return this.vipForm.controls;
  }

  onVipFormSubmit()  {
    this.submitted = true;
    if (this.vipForm.valid)  {
      const vipFormData = this.vipForm.value;
      const vipForm = new FormData();

      this.pageService.postAboutData(vipFormData).subscribe(

        response => {
          // console.log( response);
          if (response.status === 200) {
            this.responseText = response.message ? response.message : 'Requested successfully!';
            this.revert();
          }
        }
      );
    }
  }

  revert() {
    this.vipForm.reset();
  }

}
